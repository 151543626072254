import { LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, WhatsappShareButton } from 'react-share'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import OpenSollicitation from '~/components/elements/Modals/OpenSollicitation'
import Sollicitation from '~/components/elements/Modals/Sollicitation'
import { ElectraTag, GasTag, Tags, TelecomTag, WaterTag } from '~/components/elements/Vacancies/Preview'
import type { Assignment, Page_Flexcontent_Flex_Text, Vacancy } from '~/graphql/types'

export default function VacancyContent({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  const { page, request } = useLoaderData<{ page: Vacancy | Assignment; request: any }>()

  return (
    <section id="VacancyContent" className="bg-lm-shoe section">
      {/* @ts-ignore */}
      <Sollicitation fields={fields} />
      <OpenSollicitation fields={fields} />
      <div className="container">
        <div className="max-w-screen-lg mx-auto flex flex-col gap-5 lg:gap-11">
          {/* BACK BUTTON */}
          <div className="flex justify-start relative z-40">
            <Button to={page.contentType?.node.name === 'vacancy' ? '/vacatures/' : '/projecten/'} className="button-white-outline">
              TERUG NAAR HET OVERZICHT
            </Button>
          </div>
          <div className="bg-lm-beluga relative gap-x-5 gap-y-10 lg:gap-11 px-4 md:px-10 lg:px-20 py-4 md:py-12 lg:py-16 shadow-lm-basic grid grid-cols-3 lg:grid-cols-5">
            {/* TITEL WITH TAGS */}
            <div className="col-span-3 lg:col-span-4 flex flex-col gap-4 lg:gap-12">
              <div className="lg:absolute -mt-4 lg:-mt-0 right-4 md:right-10 lg:right-28 top-0">
                <Tags big className="" />
                {page.categories?.edges.some((edge) => edge.node.databaseId === 87) && <GasTag big className="-mt-[17px]" />}
                {page.categories?.edges.some((edge) => edge.node.databaseId === 88) && <WaterTag big className="-mt-[17px]" />}
                {page.categories?.edges.some((edge) => edge.node.databaseId === 89) && <ElectraTag big className="-mt-[17px]" />}
                {page.categories?.edges.some((edge) => edge.node.databaseId === 90) && <TelecomTag big className="-mt-[17px]" />}
              </div>
              <div className="flex flex-col gap-6">
                <div className="grid grid-cols-2 lg:grid-cols-3">
                  <h1 className="col-span-2 text-3xl md:text-4xl lg:text-5xl font-secondary font-semibold">{page.title}</h1>
                </div>
                <div className="flex flex-col lg:flex-row gap-4 lg:gap-9">
                  <div className="flex items-center gap-3 font-bold font-main">
                    <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 9.41618C0 4.22409 4.29709 0 9.57895 0C14.8608 0 19.1578 4.22409 19.1579 9.41618C19.1579 15.8597 10.5857 25.3192 10.2207 25.7188C9.87852 26.0934 9.28 26.0941 8.93719 25.7188C8.57222 25.3192 0 15.8597 0 9.41618ZM4.75977 9.4162C4.75977 12.0285 6.92169 14.1537 9.57912 14.1537C12.2365 14.1537 14.3984 12.0285 14.3984 9.41625C14.3984 6.80396 12.2365 4.67871 9.57912 4.67871C6.92174 4.67871 4.75977 6.80391 4.75977 9.4162Z"
                        fill="#F58A2E"
                      />
                    </svg>

                    {page.recap?.location}
                  </div>
                  <div className="flex items-center gap-3 font-bold font-main">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_48_2172)">
                        <path
                          d="M8.625 4.83333C8.625 2.71637 11.8431 1 15.8125 1C19.7819 1 23 2.71637 23 4.83333C23 6.95029 19.7819 8.66667 15.8125 8.66667C11.8431 8.66667 8.625 6.95029 8.625 4.83333ZM15.8125 10.5833C14.8283 10.5833 13.8949 10.4731 13.042 10.2815C11.7396 9.30492 9.60537 8.66667 7.1875 8.66667C3.21808 8.66667 0 10.383 0 12.5C0 14.617 3.21808 16.3333 7.1875 16.3333C11.1569 16.3333 14.375 14.617 14.375 12.5C14.375 12.4722 14.3683 12.4454 14.3673 12.4176H14.375V14.4167C14.375 16.5336 11.1569 18.25 7.1875 18.25C3.21808 18.25 0 16.5336 0 14.4167V16.3333C0 18.4503 3.21808 20.1667 7.1875 20.1667C11.1569 20.1667 14.375 18.4503 14.375 16.3333V18.25C14.375 20.367 11.1569 22.0833 7.1875 22.0833C3.21808 22.0833 0 20.367 0 18.25V20.1667C0 22.2836 3.21808 24 7.1875 24C11.1569 24 14.375 22.2836 14.375 20.1667V20.09C14.8398 20.1398 15.3199 20.1667 15.8125 20.1667C19.7819 20.1667 23 18.4503 23 16.3333V14.4167C23 16.4474 20.0368 18.1053 16.2917 18.2375V16.3209C20.0368 16.1886 23 14.5317 23 12.5V10.5833C23 12.614 20.0368 14.272 16.2917 14.4042V12.4875C20.0368 12.3553 23 10.6983 23 8.66667V6.75C23 8.86696 19.7819 10.5833 15.8125 10.5833Z"
                          fill="#F58A2E"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_48_2172">
                          <rect width="23" height="23" fill="white" transform="translate(0 1)" />
                        </clipPath>
                      </defs>
                    </svg>

                    {page.recap?.salary}
                  </div>
                  <div className="flex items-center gap-3 font-bold font-main">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_48_2175)">
                        <path
                          d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0V0ZM13 12.433L7.94701 15.6L6.88601 13.9L11 11.325V6H13V12.433Z"
                          fill="#F58A2E"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_48_2175">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    {page.recap?.contracttype}
                  </div>
                </div>
              </div>
            </div>
            {/* INTRO */}
            <div className="col-span-3 lg:col-span-5">
              <Content className="children-h2:text-3xl children-li:font-light children-li:text-lm-shoe">{fields.intro}</Content>
            </div>
            {/* GENERAL DESCRIPTION */}
            <div className="col-span-3">
              <Content className="first:children-h2:text-lm-cadmium children-h2:text-3xl children-li:font-light children-li:text-lm-shoe">
                {fields.description}
              </Content>
            </div>
            {/* CTA BLOCK */}
            <div className="col-span-3 lg:col-span-2 lg:mt-0 md:mt-20">
              <div
                className={clsx(
                  page?.recap?.member?.title === 'Theun de Beer' ? 'pr-8' : 'pr-28',
                  'bg-lm-machine max-w-[350px] relative pr-28 pl-8 py-4 lg:py-10 flex items-center shadow '
                )}
              >
                {page?.recap?.member?.title === 'Theun de Beer' ? (
                  <>
                    {/* <LossePlaatjie
                      maxWidth={1000}
                      className="absolute -right-10 md:-right-28 bottom-0 w-[140px] md:w-[240px]"
                      src={page.recap?.member?.recap?.image}
                      alt="cta-image"
                    /> */}
                    <div className="flex flex-col gap-6">
                      <Content className="text-white children-p:leading-[1.3]">{page.recap?.member?.recap?.extractatext}</Content>
                      <div>
                        <a className="flex items-center gap-3 hover:underline text-white font-secondary" href="tel:+31880228900">
                          <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M27.9211 19.9234C26.1013 19.9234 24.319 19.6393 22.6297 19.0819C21.8052 18.7978 20.869 19.0167 20.3271 19.5694L16.9781 22.0987C13.1354 20.0477 10.676 17.5898 8.65293 13.775L11.1138 10.5052C11.7333 9.88566 11.9554 8.9789 11.6899 8.12964C11.1278 6.4295 10.8421 4.64553 10.8421 2.82895C10.8421 1.5449 9.79724 0.5 8.51319 0.5H3.07895C1.7949 0.5 0.75 1.5449 0.75 2.82895C0.75 17.8103 12.9397 30 27.9211 30C29.2051 30 30.25 28.9551 30.25 27.6711V22.2524C30.25 20.9683 29.2051 19.9234 27.9211 19.9234Z"
                              fill="#F7951F"
                            />
                          </svg>
                          <div>
                            <div>
                              <strong>BEL ONS</strong>
                            </div>
                            +31 (0) 88 022 89 00
                          </div>
                        </a>
                      </div>
                      <div>
                        <a
                          className="flex items-center gap-3 hover:underline text-white font-secondary"
                          href="https://wa.me/+31657207771"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M28.2439 4.85951C21.8344 -1.56879 11.3838 -1.6268 4.9016 4.72932C-0.20802 9.73842 -1.46235 17.4506 1.79891 23.799L0.0480466 32.2317C0.0103516 32.4018 0.0467467 32.5797 0.148133 32.7228C0.296313 32.9406 0.565378 33.045 0.822743 32.9832L9.14163 31.0252C17.3032 35.052 27.2118 31.7535 31.2725 23.6598C34.4155 17.3926 33.1936 9.83768 28.2322 4.8608H28.2452L28.2439 4.85951ZM25.6429 25.427C21.7162 29.3134 15.7279 30.2866 10.756 27.8452L9.59137 27.2716L4.48176 28.4703V28.4046L5.55931 23.2937L4.98999 22.1825C2.44623 17.2353 3.4068 11.2272 7.36737 7.30219C12.4159 2.30082 20.5944 2.30082 25.6429 7.30219L25.7001 7.38082C30.6811 12.3964 30.6512 20.4553 25.6338 25.4347L25.6429 25.4257V25.427Z"
                              fill="#F7951F"
                            />
                            <path
                              d="M25.1915 21.7101C24.565 22.6871 23.5719 23.8808 22.3319 24.1811C20.143 24.7019 16.8102 24.1811 12.6378 20.3553L12.5858 20.3076C8.95665 16.9472 7.99347 14.1461 8.20795 11.9278C8.33533 10.6645 9.39469 9.52632 10.2877 8.77998C10.7907 8.35977 11.542 8.42293 11.9657 8.92177C12.0411 9.00943 12.1022 9.10868 12.149 9.21438L13.493 12.2281C13.6724 12.6238 13.6165 13.084 13.3488 13.4269L12.6664 14.2957C12.3765 14.6643 12.3401 15.1709 12.5741 15.5769C13.2227 16.5257 13.9987 17.3816 14.8813 18.1215C15.8301 19.0276 16.9077 19.792 18.0775 20.3927C18.5143 20.5667 19.0147 20.4623 19.3423 20.1284L20.1261 19.3383C20.4302 19.0135 20.8917 18.8871 21.3219 19.0083L24.5182 19.9158C25.1551 20.0962 25.5255 20.7536 25.3436 21.3852C25.3098 21.5012 25.2591 21.6121 25.1928 21.7126L25.1915 21.7101Z"
                              fill="#F7951F"
                            />
                          </svg>
                          <div>
                            <strong>Whatsapp</strong>
                            {` `}
                            ons
                          </div>
                        </a>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <LossePlaatjie
                      maxWidth={1000}
                      className="absolute -right-10 md:-right-28 bottom-0 w-[140px] md:w-[240px]"
                      src={page.recap?.member?.recap?.image}
                      alt="cta-image"
                    />
                    <div className="flex flex-col gap-6">
                      <Content className="text-white children-p:leading-[1.3]">{page.recap?.member?.recap?.extractatext}</Content>
                      <div>
                        <a
                          className="flex items-center gap-3 hover:underline text-white font-secondary"
                          href={page.recap?.member?.recap?.phone?.url || '/'}
                        >
                          <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M27.9211 19.9234C26.1013 19.9234 24.319 19.6393 22.6297 19.0819C21.8052 18.7978 20.869 19.0167 20.3271 19.5694L16.9781 22.0987C13.1354 20.0477 10.676 17.5898 8.65293 13.775L11.1138 10.5052C11.7333 9.88566 11.9554 8.9789 11.6899 8.12964C11.1278 6.4295 10.8421 4.64553 10.8421 2.82895C10.8421 1.5449 9.79724 0.5 8.51319 0.5H3.07895C1.7949 0.5 0.75 1.5449 0.75 2.82895C0.75 17.8103 12.9397 30 27.9211 30C29.2051 30 30.25 28.9551 30.25 27.6711V22.2524C30.25 20.9683 29.2051 19.9234 27.9211 19.9234Z"
                              fill="#F7951F"
                            />
                          </svg>
                          <div>
                            <div>
                              <strong>BEL</strong>
                              {` `}
                              {page.recap?.member?.title?.split(' ')[0]}
                            </div>
                            {page.recap?.member?.recap?.phone?.title}
                          </div>
                        </a>
                      </div>
                      <div>
                        <a
                          className="flex items-center gap-3 hover:underline text-white font-secondary"
                          href={page.recap?.member?.recap?.whatsapp?.url || '/'}
                        >
                          <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M28.2439 4.85951C21.8344 -1.56879 11.3838 -1.6268 4.9016 4.72932C-0.20802 9.73842 -1.46235 17.4506 1.79891 23.799L0.0480466 32.2317C0.0103516 32.4018 0.0467467 32.5797 0.148133 32.7228C0.296313 32.9406 0.565378 33.045 0.822743 32.9832L9.14163 31.0252C17.3032 35.052 27.2118 31.7535 31.2725 23.6598C34.4155 17.3926 33.1936 9.83768 28.2322 4.8608H28.2452L28.2439 4.85951ZM25.6429 25.427C21.7162 29.3134 15.7279 30.2866 10.756 27.8452L9.59137 27.2716L4.48176 28.4703V28.4046L5.55931 23.2937L4.98999 22.1825C2.44623 17.2353 3.4068 11.2272 7.36737 7.30219C12.4159 2.30082 20.5944 2.30082 25.6429 7.30219L25.7001 7.38082C30.6811 12.3964 30.6512 20.4553 25.6338 25.4347L25.6429 25.4257V25.427Z"
                              fill="#F7951F"
                            />
                            <path
                              d="M25.1915 21.7101C24.565 22.6871 23.5719 23.8808 22.3319 24.1811C20.143 24.7019 16.8102 24.1811 12.6378 20.3553L12.5858 20.3076C8.95665 16.9472 7.99347 14.1461 8.20795 11.9278C8.33533 10.6645 9.39469 9.52632 10.2877 8.77998C10.7907 8.35977 11.542 8.42293 11.9657 8.92177C12.0411 9.00943 12.1022 9.10868 12.149 9.21438L13.493 12.2281C13.6724 12.6238 13.6165 13.084 13.3488 13.4269L12.6664 14.2957C12.3765 14.6643 12.3401 15.1709 12.5741 15.5769C13.2227 16.5257 13.9987 17.3816 14.8813 18.1215C15.8301 19.0276 16.9077 19.792 18.0775 20.3927C18.5143 20.5667 19.0147 20.4623 19.3423 20.1284L20.1261 19.3383C20.4302 19.0135 20.8917 18.8871 21.3219 19.0083L24.5182 19.9158C25.1551 20.0962 25.5255 20.7536 25.3436 21.3852C25.3098 21.5012 25.2591 21.6121 25.1928 21.7126L25.1915 21.7101Z"
                              fill="#F7951F"
                            />
                          </svg>
                          <div>
                            <strong>{page.recap?.member?.recap?.whatsapp?.title?.split(' ')[0]}</strong>
                            {` `}
                            {page.recap?.member?.recap?.whatsapp?.title?.split(' ')[1]}
                          </div>
                        </a>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* WORKMETHOD BLOCK */}
            <div className="col-span-3 lg:col-span-5 flex flex-col gap-8 lg:gap-20">
              <h2 className="text-3xl font-secondary font-semibold">ONZE WERKWIJZE</h2>
              <div className="grid w-full lg:gap-0 gap-12 grid-cols-1 lg:grid-cols-5 justify-center ">
                {fields.items?.map((item, index) => (
                  <div key={index}>
                    <div className="lg:block hidden">
                      <div className="flex justify-center lg:pb-3">
                        <LossePlaatjie
                          maxWidth={1000}
                          className="w-[60px] h-[60] lg:w-[100px] lg:h-[80px] object-contain"
                          src={item?.icon}
                        />
                      </div>
                      <div className="relative pt-5 pb-4 flex justify-center">
                        <div className="border-[3px] rounded-full border-solid border-lm-machine bg-white w-[50px] h-[50px] flex justify-center items-center relative z-20 font-secondary text-3xl font-bold text-lm-machine">
                          {index + 1}
                        </div>
                        {index !== (fields.items?.length || 0) - 1 && (
                          <div className="bg-lm-cadmium h-[2px] w-[300px] left-[50%] absolute z-10 top-0 bottom-0 my-auto" />
                        )}
                      </div>
                      <Content className="text-center px-3 text-lm-machine leading-[30px]">{item?.description}</Content>
                    </div>
                    <div className="lg:hidden grid grid-cols-12 relative">
                      {index % 2 === 0 && (
                        <div className="col-span-6 flex justify-center items-center">
                          <div className="border-[3px] border-solid border-lm-machine bg-white w-[50px] h-[50px] flex justify-center items-center relative z-20 font-secondary text-3xl font-bold text-lm-machine">
                            {index + 1}
                          </div>
                        </div>
                      )}
                      <div className="col-span-6">
                        <div className="flex justify-center lg:pb-3">
                          <LossePlaatjie
                            maxWidth={1000}
                            className="w-[60px] h-[60] lg:w-[100px] lg:h-[80px] object-contain"
                            src={item?.icon}
                          />
                        </div>
                        <Content className="text-center text-lm-machine leading-[30px]">{item?.description}</Content>
                      </div>
                      {index % 2 !== 0 && (
                        <div className="col-span-6 flex justify-center items-center">
                          <div className="border-[3px] border-solid border-lm-machine bg-white w-[50px] h-[50px] flex justify-center items-center relative z-20 font-secondary text-3xl font-bold text-lm-machine">
                            {index + 1}
                          </div>
                        </div>
                      )}
                      {index !== (fields.items?.length || 0) - 1 && (
                        <div className="bg-lm-cadmium h-[2px] w-full lg:w-[200px] absolute z-10 top-[calc(100%_+_24px)] lg:top-[72px]" />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* SUB DESCRIPTION */}
            <div className="col-span-3 lg:col-span-5">
              <Content className="children-h2:text-3xl children-h2:mb-0 children-li:font-light children-li:text-lm-shoe">
                {fields.subdescription}
              </Content>
            </div>
            {/* SHARE */}
            <div className="col-span-3 lg:col-span-5 py-10 grid grid-cols-1 gap-6 lg:gap-4 lg:grid-cols-1">
              <div className="col-span-1">
                <Content className="text-lm-shoe children-h2:text-3xl children-h2:mb-0">{fields.share?.description}</Content>
              </div>
              <div className="col-span-1 flex flex-row gap-6">
                <LinkedinShareButton className="group" url={request.url}>
                  <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 23.1279C0 10.7015 10.0736 0.62793 22.5 0.62793C34.9264 0.62793 45 10.7015 45 23.1279C45 35.5543 34.9264 45.6279 22.5 45.6279C10.0736 45.6279 0 35.5543 0 23.1279ZM16.2817 34.7887V19.2412H10.8398V34.7887H16.2817ZM13.5608 16.9087C12.058 16.9087 10.8398 15.6905 10.8398 14.1877C10.8398 12.685 12.058 11.4668 13.5608 11.4668C15.0635 11.4668 16.2817 12.685 16.2817 14.1877C16.2817 15.6904 15.0636 16.9086 13.5609 16.9087H13.5608ZM29.4965 34.7886H34.1605L34.1607 26.2443C34.1607 22.0489 33.2557 19.2411 28.3653 19.2411C26.0149 19.2411 24.4374 20.1143 23.792 21.3393H23.7253V19.2411H19.3905V34.7886H23.9207V27.0822C23.9207 25.0504 24.3052 23.0823 26.8196 23.0823C29.2959 23.0823 29.4965 25.4063 29.4965 27.2131V34.7886Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                  </svg>
                </LinkedinShareButton>
                <FacebookShareButton className="group" url={request.url}>
                  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M26.1269 44.0771C25.9395 44.1119 25.7513 44.1439 25.5625 44.1732C25.7513 44.1439 25.9395 44.1119 26.1269 44.0771Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M26.5774 44.0047C26.4878 44.0234 26.3982 44.0406 26.3086 44.0582C26.3982 44.0406 26.4878 44.0234 26.5774 44.0047Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M25.06 44.2229C24.8406 44.251 24.6199 44.276 24.3984 44.2971C24.6199 44.276 24.8406 44.251 25.06 44.2229Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M25.493 44.1733C25.3876 44.1893 25.2815 44.2042 25.1758 44.2182C25.2815 44.2042 25.3876 44.1893 25.493 44.1733Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M27.1905 43.885C27.1115 43.9041 27.0322 43.9232 26.9529 43.9416C27.0322 43.9232 27.1115 43.9041 27.1905 43.885Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M28.7111 43.5139C28.6472 43.5346 28.5834 43.5541 28.5195 43.5744C28.5834 43.5541 28.6472 43.5346 28.7111 43.5139Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M28.2517 43.6373C28.1824 43.658 28.1127 43.6791 28.043 43.699C28.1127 43.6791 28.1824 43.6584 28.2517 43.6373Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M27.6495 43.7851C27.576 43.8046 27.5019 43.823 27.4277 43.8417C27.5019 43.823 27.576 43.8046 27.6495 43.7851Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M24.3928 44.2906C24.2743 44.302 24.1559 44.3121 24.0371 44.3211C24.1559 44.3121 24.2743 44.302 24.3928 44.2906Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M45 22.2614C45 10.056 34.9245 0.158203 22.5 0.158203C10.0755 0.158203 0 10.056 0 22.2614C0 34.4669 10.0755 44.3647 22.5 44.3647C22.6322 44.3647 22.7637 44.362 22.8955 44.3596V27.1532H18.0615V21.6189H22.8955V17.5464C22.8955 12.8233 25.8306 10.2527 30.1194 10.2527C32.1731 10.2527 33.9385 10.4031 34.4531 10.4702V15.4065H31.4957C29.1625 15.4065 28.7107 16.4958 28.7107 18.0942V21.6189H34.2897L33.5625 27.1532H28.7107V43.5107C38.1153 40.8622 45 32.3505 45 22.2614Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M23.9864 44.3191C23.7523 44.3363 23.5171 44.35 23.2812 44.359C23.5171 44.35 23.7523 44.3363 23.9864 44.3191Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      d="M23.27 44.3563C23.1457 44.3609 23.0211 44.3645 22.8965 44.3668C23.0211 44.3645 23.1457 44.3609 23.27 44.3563Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                  </svg>
                </FacebookShareButton>
                <WhatsappShareButton className="group" url={request.url}>
                  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.0894 30.6642L13.1525 31.9155L14.4321 28.1407C13.1631 26.4126 12.4794 24.3244 12.4807 22.1805C12.4807 16.5752 17.0772 12.0146 22.7261 12.0146C28.3749 12.0146 32.9709 16.5752 32.9709 22.1805C32.9709 27.7857 28.3744 32.3459 22.7255 32.3459C20.7228 32.3486 18.7633 31.7639 17.0894 30.6642ZM18.6234 16.6602C18.3179 16.668 18.0295 16.8022 17.8267 17.0308C17.809 17.0501 17.7891 17.0711 17.7675 17.094C17.4562 17.4237 16.7815 18.1384 16.7815 19.5024C16.7815 20.9553 17.8437 22.3595 17.9993 22.5653L18.0011 22.5676C18.0125 22.5826 18.0341 22.6145 18.0657 22.6611C18.4489 23.2251 20.2972 25.9463 23.1031 27.042C25.6196 28.0247 26.0518 27.9212 26.437 27.8289C26.5168 27.8098 26.5945 27.7912 26.6883 27.7828C27.2352 27.7338 28.4555 27.066 28.7043 26.3741C28.9531 25.6821 28.9538 25.0877 28.8787 24.9647C28.8182 24.8655 28.6771 24.7979 28.4677 24.6976C28.4177 24.6737 28.3639 24.6479 28.3063 24.6194C28.0074 24.4713 26.5393 23.7545 26.2651 23.6556C25.9909 23.5567 25.7912 23.5061 25.5925 23.8034C25.3938 24.1007 24.8209 24.767 24.6467 24.9651C24.4724 25.1632 24.2983 25.1877 23.9997 25.0396C23.951 25.0154 23.8847 24.9869 23.8028 24.9517C23.3825 24.771 22.5524 24.4141 21.5976 23.5688C20.9451 22.9708 20.3851 22.2792 19.936 21.5165C19.7616 21.22 19.9175 21.0596 20.0668 20.9121C20.1553 20.8243 20.2568 20.702 20.3585 20.5794C20.4112 20.5158 20.4641 20.4521 20.5151 20.3932C20.6378 20.2435 20.7384 20.0769 20.8136 19.8986C20.8944 19.7325 20.885 19.5368 20.7888 19.3792C20.7392 19.2807 20.4577 18.6009 20.2016 17.9826C20.0731 17.6723 19.951 17.3775 19.8679 17.1793C19.6487 16.6571 19.4298 16.6712 19.2616 16.6821C19.2388 16.6835 19.2169 16.685 19.196 16.685C19.1118 16.685 19.0218 16.6792 18.9288 16.6732C18.8294 16.6668 18.7264 16.6602 18.6234 16.6602Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M45 22.5C45 34.9264 34.9264 45 22.5 45C10.0736 45 0 34.9264 0 22.5C0 10.0736 10.0736 0 22.5 0C34.9264 0 45 10.0736 45 22.5ZM22.7264 10.0889C15.9963 10.0889 10.5407 15.5023 10.5407 22.1804C10.538 24.3825 11.1429 26.5427 12.2889 28.4231L10.0898 34.9109L16.8362 32.7672C18.6414 33.7573 20.6676 34.2747 22.7265 34.2716C29.4565 34.2716 34.9118 28.8585 34.9118 22.1804C34.9118 15.5023 29.4565 10.0889 22.7264 10.0889Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                  </svg>
                </WhatsappShareButton>
                <EmailShareButton className="group" url={request.url}>
                  <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M22.4995 0.5C10.0736 0.5 0.000244141 10.5737 0.000244141 22.9995C0.000244141 35.4251 10.0736 45.4995 22.4995 45.4995C34.9253 45.4995 44.9995 35.4251 44.9995 22.9995C44.9995 10.5737 34.9253 0.5 22.4995 0.5ZM22.5002 8.40332L35.8147 16.7058H9.18579L22.5002 8.40332ZM35.9797 29.9634H35.9811V17.2336C35.9811 17.0925 35.9667 16.9558 35.9433 16.8213L23.2221 24.7537C23.2132 24.7593 23.204 24.7638 23.1947 24.7683C23.1877 24.7718 23.1806 24.7752 23.1738 24.7791C23.157 24.7884 23.1399 24.7974 23.1228 24.8059C23.0325 24.8523 22.9402 24.8896 22.8457 24.9141C22.8404 24.9156 22.835 24.9166 22.8296 24.9175C22.8252 24.9184 22.8209 24.9192 22.8165 24.9202C22.7127 24.9451 22.6075 24.96 22.5025 24.96H22.501H22.5003C22.3953 24.96 22.2901 24.9447 22.1863 24.9202C22.1766 24.918 22.1668 24.9168 22.1571 24.9141C22.0624 24.8896 21.9699 24.8523 21.88 24.8059C21.8715 24.8017 21.8629 24.7973 21.8544 24.7928C21.8459 24.7883 21.8374 24.7837 21.829 24.7791C21.8222 24.7753 21.8153 24.7719 21.8084 24.7685C21.7991 24.7639 21.7897 24.7594 21.7807 24.7537L9.05953 16.8213C9.03613 16.9558 9.02173 17.0928 9.02173 17.2336V29.9634C9.02173 31.2949 10.1008 32.3742 11.4325 32.3742H33.5689C34.9005 32.3742 35.9797 31.2951 35.9797 29.9634Z"
                      fill="#979797"
                      className="group-hover:fill-lm-cadmium smooth"
                    />
                  </svg>
                </EmailShareButton>
              </div>
            </div>
          </div>
          <section id="SmallCTA" className="bg-lm-shoe pt-6 lg:pt-20">
            <div className="bg-lm-cadmium relative py-5">
              <div className="w-[4000px] -left-[1000px] bg-lm-cadmium top-0 absolute h-full z-10" />
              <div className="max-w-screen-lg relative z-20 mx-auto gap-12 lg:gap-32 flex flex-col lg:flex-row justify-between">
                <div>
                  <Content className="text-white children-p:text-xl children-p:font-secondary children-p:font-semibold">
                    {fields.opensollicitation?.description}
                  </Content>
                </div>
                <div className="flex items-center relative">
                  <div
                    className={clsx(
                      'bg-lm-shoe',
                      'w-[200%] lg:w-[20px] h-[10px] lg:h-[200%] lg:-rotate-[20deg] absolute -left-[50%] right-0 mx-auto lg:right-[unset] lg:-left-20 -top-[90%] lg:-top-[50%]'
                    )}
                  />
                  <Button className="button-white-outline" to={fields.opensollicitation?.button?.url || '/'}>
                    {fields.opensollicitation?.button?.title}
                  </Button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  )
}
