import { Link, LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function CTA({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section id="CTA">
      <div className="container section">
        <div className="grid grid-cols-3 md:grid-cols-7 gap-6 md:gap-10 lg:gap-16 xl:gap-20 2xl:gap-32 max-w-screen-xl mx-auto">
          <div className="col-span-3 flex flex-col gap-10 lg:gap-32">
            <div
              className={clsx(
                fields?.cta?.image ? 'pl-28' : 'pl-8',
                'bg-lm-machine max-w-[350px] relative pl-28 pr-8 py-10 flex items-center shadow-lm-basic'
              )}
            >
              {fields?.cta?.image && (
                <LossePlaatjie
                  maxWidth={2000}
                  className="absolute -left-10 md:-left-28 bottom-0 w-[140px] md:w-[210px]"
                  src={fields.cta?.image}
                  alt="cta-image"
                />
              )}
              <div className="flex flex-col gap-6">
                <Content className="text-white">{fields.cta?.description}</Content>
                <div>
                  <a className="flex items-center gap-3 hover:underline text-white font-secondary" href={fields.cta?.phone?.url || '/'}>
                    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M27.9211 19.9234C26.1013 19.9234 24.319 19.6393 22.6297 19.0819C21.8052 18.7978 20.869 19.0167 20.3271 19.5694L16.9781 22.0987C13.1354 20.0477 10.676 17.5898 8.65293 13.775L11.1138 10.5052C11.7333 9.88566 11.9554 8.9789 11.6899 8.12964C11.1278 6.4295 10.8421 4.64553 10.8421 2.82895C10.8421 1.5449 9.79724 0.5 8.51319 0.5H3.07895C1.7949 0.5 0.75 1.5449 0.75 2.82895C0.75 17.8103 12.9397 30 27.9211 30C29.2051 30 30.25 28.9551 30.25 27.6711V22.2524C30.25 20.9683 29.2051 19.9234 27.9211 19.9234Z"
                        fill="#F7951F"
                      />
                    </svg>
                    <div>
                      <div>
                        <strong>{fields.cta?.phone?.title?.split(' ')[0]}</strong>
                        {` `}
                        {fields.cta?.phone?.title?.split(' ')[1]}
                      </div>
                      {fields.cta?.phone?.title?.split(' ').slice(2, fields.cta?.phone?.title?.split(' ').length).join(' ')}
                    </div>
                  </a>
                </div>
                <div>
                  <a className="flex items-center gap-3 hover:underline text-white font-secondary" href={fields.cta?.whatsapp?.url || '/'}>
                    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M28.2439 4.85951C21.8344 -1.56879 11.3838 -1.6268 4.9016 4.72932C-0.20802 9.73842 -1.46235 17.4506 1.79891 23.799L0.0480466 32.2317C0.0103516 32.4018 0.0467467 32.5797 0.148133 32.7228C0.296313 32.9406 0.565378 33.045 0.822743 32.9832L9.14163 31.0252C17.3032 35.052 27.2118 31.7535 31.2725 23.6598C34.4155 17.3926 33.1936 9.83768 28.2322 4.8608H28.2452L28.2439 4.85951ZM25.6429 25.427C21.7162 29.3134 15.7279 30.2866 10.756 27.8452L9.59137 27.2716L4.48176 28.4703V28.4046L5.55931 23.2937L4.98999 22.1825C2.44623 17.2353 3.4068 11.2272 7.36737 7.30219C12.4159 2.30082 20.5944 2.30082 25.6429 7.30219L25.7001 7.38082C30.6811 12.3964 30.6512 20.4553 25.6338 25.4347L25.6429 25.4257V25.427Z"
                        fill="#F7951F"
                      />
                      <path
                        d="M25.1915 21.7101C24.565 22.6871 23.5719 23.8808 22.3319 24.1811C20.143 24.7019 16.8102 24.1811 12.6378 20.3553L12.5858 20.3076C8.95665 16.9472 7.99347 14.1461 8.20795 11.9278C8.33533 10.6645 9.39469 9.52632 10.2877 8.77998C10.7907 8.35977 11.542 8.42293 11.9657 8.92177C12.0411 9.00943 12.1022 9.10868 12.149 9.21438L13.493 12.2281C13.6724 12.6238 13.6165 13.084 13.3488 13.4269L12.6664 14.2957C12.3765 14.6643 12.3401 15.1709 12.5741 15.5769C13.2227 16.5257 13.9987 17.3816 14.8813 18.1215C15.8301 19.0276 16.9077 19.792 18.0775 20.3927C18.5143 20.5667 19.0147 20.4623 19.3423 20.1284L20.1261 19.3383C20.4302 19.0135 20.8917 18.8871 21.3219 19.0083L24.5182 19.9158C25.1551 20.0962 25.5255 20.7536 25.3436 21.3852C25.3098 21.5012 25.2591 21.6121 25.1928 21.7126L25.1915 21.7101Z"
                        fill="#F7951F"
                      />
                    </svg>
                    <div>
                      <strong>{fields.cta?.whatsapp?.title?.split(' ')[0]}</strong>
                      {` `}
                      {fields.cta?.whatsapp?.title?.split(' ')[1]}
                    </div>
                  </a>
                </div>
              </div>
            </div>
            {fields.part?.description && (
              <div className="flex flex-col gap-5">
                <h3 className="text-2xl font-secondary font-medium text-white">{fields.part.description?.title}</h3>
                <LosseLink target="_blank" to={fields.part.description?.url || '/'}>
                  <LossePlaatjie maxWidth={1000} src={fields.part.icon} alt="company-icon" />
                </LosseLink>
              </div>
            )}
          </div>
          <div className="flex col-span-3 md:col-span-4 justify-center flex-col gap-10">
            <Content className="text-white">{fields.description}</Content>
            <div>
              {fields.links?.map((link, index) => (
                <Link className="button-cadmium-lg" key={index} to={link?.link?.url || '/'}>
                  {link?.link?.title}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
